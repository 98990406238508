import { Box, Button, Flex, Heading, Text } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import productRoutes from '../../products/routes'
import { LongArrowRight } from '../../shared/components/Icons/LongArrowRight'
import PageHeader from '../../shared/components/PageHeader'
import ShopifyNextLink from '../../shared/components/ShopifyNextLink'

const Error404Index = () => {
  const { t } = useTranslation()

  return (
    <PageHeader title="Page not Found">
      <Box>
        <Flex
          direction="column"
          align="center"
          justify="center"
          px={[6, 12, 40]}
          py={[20, 24, 40]}
          size="lg"
        >
          <Heading size="xs">
            {t('common:404.page-not-found', '404 Page Not Found')}
          </Heading>
          <Text size="sm" py={[8, 8, 4]} px={[1, null, 4]}>
            {t(
              'common:404.page-requested-does-not-exist',
              'The page you requested does not exist.'
            )}
          </Text>
          <ShopifyNextLink href={productRoutes.products()}>
            <Button variant="primary" textTransform="uppercase" px={[4, 4, 8]}>
              {t('common:continue-shopping', 'Continue Shopping')}
              <LongArrowRight size="1.5em" name="long-arrow-right" ml="4" />
            </Button>
          </ShopifyNextLink>
        </Flex>
      </Box>
    </PageHeader>
  )
}

export default Error404Index
